import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TasksPage from "./Pages/Tasks/TaskPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ProjectsListPage from "./Pages/Projects/ProjectsList";
import AdminOverview from "./Pages/Admin/AdminOverview";
import ProjectCreate from "./Pages/Admin/Project/ProjectCreate";
import TaskCreate from "./Pages/Admin/Task/TaskCreate";
import CategoryCreate from "./Pages/Admin/Category/CategoryCreate";
import ProjectOverview from "./Pages/Projects/ProjectOverview";
import WikiDetails from "./Pages/Wiki/WikiDetails";
import UserDetails from "./Pages/Users/UsersDetails";
import ThreadsList from "./Pages/Threads/Threads";
import LoginPage from "./Pages/Auth/LoginPage";
import DepartmentPage from "./Pages/Department/DepartmentPage";
import UserCreate from "./Pages/Admin/Users/CreateUserPage";
import WikiUpdate from "./Pages/Wiki/WikiUpdate";
import WikiCreate from "./Pages/Wiki/WikiCreate";
import CreateThreadPage from "./Pages/Threads/CreateThread";
import ProjectTasksPage from "./Pages/Tasks/ProjectTasksPage";

function App() {
  // create - project;
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/active-tasks" element={<TasksPage />} />
        <Route path="/project-tasks/:id" element={<ProjectTasksPage />} />
        <Route path="/overview" element={<ProjectsListPage />} />
        <Route path="/project-overview/:id" element={<ProjectOverview />} />
        <Route path="/department/:id" element={<DepartmentPage />} />
        <Route path="/wiki-details/:id" element={<WikiDetails />} />
        <Route path="/wiki-update/:id" element={<WikiUpdate />} />
        <Route path="/wiki-create/:id" element={<WikiCreate />} />
        <Route path="/user-details/:id" element={<UserDetails />} />
        <Route path="/create-project" element={<ProjectCreate />} />
        <Route path="/create-category" element={<CategoryCreate />} />
        <Route path="/create-task" element={<TaskCreate />} />
        <Route path="/threads/:id" element={<ThreadsList />} />
        <Route path="/threads/create/:id" element={<CreateThreadPage />} />
        {/* Admin */}
        <Route path="/admin" element={<AdminOverview />} />
        <Route path="/admin/users/create" element={<UserCreate />} />
      </Routes>
    </Router>
  );
}

export default App;
