import "bootstrap/dist/css/bootstrap.min.css";
import "./Threads.css"
import { apiRequest, uploadFormData } from "../../Helper/ApiRequest";
import { useState } from "react";
import Navbar from "../../Components/Navbar";
import { useLocation, useNavigate } from "react-router";
import { getCookie, userId } from "../../Helper/TokenService";
import { SpinStretch } from "react-cssfx-loading";

const CreateThreadPage: React.FC = () => {
  
  const taskId = useLocation().pathname
  const paramTaskId = taskId.split("/threads/create/")[1]

  const [threadText, setThreadText] = useState<string | null>(null);
  const [picture, setPicture] = useState(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate()

    const handleNavigate = (route: string) => {
        navigate(route)
    };

  const currentUserID = async () => {
    const storedUserId = await getCookie(userId)
    return storedUserId
  }

    const onChangePicture = (e: any) => {
        setPicture(e.target.files[0]);
    };

  const handleCreateThread = async () => {
    setIsLoading(true)
    setErrorMessage(null)
    if (!threadText && !picture) {
        setIsLoading(false)
        setErrorMessage("Error: Please provide either an image or a comment.")
        return
    }

    const formData = new FormData();
    formData.append("image", picture!);

    const imageResponse = await uploadFormData({api: "UploadMedia", formData: formData});

    console.log(imageResponse)

    if (imageResponse.error) {
      setIsLoading(false)
      setErrorMessage(imageResponse.error)
      return
    }

    const createdPost = await apiRequest({
        api: "CreateThread",
        body: {
            "description": threadText ?? "",
            "media": [imageResponse._id],
            "task": paramTaskId,
            "creator": await currentUserID()
        }
    })

    if (createdPost.error) {
        setIsLoading(false)
        setErrorMessage(createdPost.error)
        return
    }
    
    handleNavigate(`/`)
    handleNavigate(`/threads/${taskId}`)
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-center">
        <div className="form-container">
          <div className="form-content">
            <h2>Post to thread</h2>

            {errorMessage && (
              <div className="alert alert-danger mt-4" role="alert">
                {errorMessage}
              </div>
            )}

            <div className="form-floating mt-5 contact-text-area">
                <textarea value={threadText || ""} className="user-create-form form-control" id="comment" name="text" placeholder="Comment goes here" style={{
                    "height": "100px"
                }} onChange={(event) => {
                        setThreadText(event.target.value.toString());
                    }}></textarea>
                <label htmlFor="comment">Text Here</label>
            </div>

            <div className="mb-3">
                <input className="mt-4 form-control thread-media-create-form" type="file" id="formFile" onChange={onChangePicture} />
            </div>
            
            {isLoading ? (
              <SpinStretch
                color="#4089DC"
                style={{
                  display: "inline-block",
                }}
              />
            ) : (
              <button
                type="button"
                className="btn btn-outline-success mt-3"
                onClick={() => {
                  handleCreateThread();
                }}
              >
                Post to thread
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateThreadPage;
