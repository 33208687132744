import "bootstrap/dist/css/bootstrap.min.css";
import "../Projects/Projects.css"
import "../Users/Users.css"
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";

interface User {
    name: string;
    email: string;
}

interface Task {
    status: string;
    name: string;
    updated: number;
    _id: string;
}

const UserDetails: React.FC = () => {

    const userId = useLocation().pathname
    const paramUserId = userId.split("/user-details/")[1]

     const [tasksArray, setTasksArray] = useState([]);

    const [selectedUser, setSelectedUser] = useState<User>({
        name: "",
        email: ""
    })

    const statusClass: { [key: string]: string } = {
        "not started": "rounded-pill badge not-started",
        "active": "rounded-pill badge active",
        "complete": "rounded-pill badge completed",
        "blocked": "rounded-pill badge blocked",
        "sticky": "rounded-pill badge sticky",
        "updates": "rounded-pill badge updates",
    };

    async function getSelectedUser() {
        const wiki = await apiRequest({
            api: "Users",
            params: `/${paramUserId}`
        })
        setSelectedUser(wiki)
    }

    async function getTasks() {
        const tasks = await apiRequest({
          api: "Tasks",
        });
        console.log(tasks)
        setTasksArray(tasks.data);
    }

    useEffect(() => {
        getSelectedUser()
        getTasks()
    }, []);

  return (
    <div>
        <Navbar />
            <div className="d-flex" style={{
                height: "100vh",
                overflow: "hidden"
            }}>
            <Sidebar />
            <div className="content-wrapper">
                <div className="user-details-wrapper mt-5">
                    <img className="profile-image" src="https://www.meme-arsenal.com/memes/93eb7de66b8dcc05500bd950b89b0d74.jpg" width="220" height="220" />
                    <div className="user-detail-container">
                        <h2>{selectedUser.name}</h2>
                        <h4>Programmer</h4>
                        <div className="user-profile-desc">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                        </div>
                    </div>
                </div>

                <h2 className="user-tasks-title mt-5 mb-4">Assigned Tasks</h2>
                <table className="table table-stripe border">
                    <tbody>
                    <tr>
                        <td className="header-item">Status</td>
                        <td className="header-item">Task</td>
                        <td className="header-item">Replies</td>
                        <td className="header-item">Last Post</td>
                        <td className="header-item">Category</td>
                    </tr>
                    {tasksArray.map &&
                        tasksArray.map((item: any) => (
                        <tr key={item._id}>
                            <td>
                                <div className={statusClass[item.status]}>
                                    {item.status}
                                </div>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.name}</td>
                            <td>{item.updated}</td>
                            <td>{item.category.name}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  );
};

export default UserDetails;