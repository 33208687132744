import "bootstrap/dist/css/bootstrap.min.css";
import "../Tasks/Tasks.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";
import { getCookie, userId } from "../../Helper/TokenService";

interface User {
  name: string;
  media: any[];
}

const TasksPage: React.FC = () => {

  const [user, setUser] = useState<User | undefined>(undefined)
  const [tasks, setTasks] = useState([])

  const currentUserID = async () => {
    const storedUserId = await getCookie(userId)
    return storedUserId
  }

  async function getUser() {
    const storedUserId = await currentUserID()
    const userResponse = await apiRequest({
      api: "GetUser",
      params: `/${storedUserId}`
    })
    setUser(userResponse)
  }

  async function getUsersTasks() {
    const storedUserId = await currentUserID()
    const userTaskResponse = await apiRequest({
      api: "GetUserTasks",
      params: `/${storedUserId}`
    })
    setTasks(userTaskResponse)
  }

  useEffect(() => {
    getUser()
    getUsersTasks()
  }, [])

  return (
    <div>
      <Navbar />
        <div
          className="d-flex"
          style={{
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Sidebar />
          <div className="content-wrapper">
            <div
              className="d-flex p-4 pb-5
            pt-5 flex-row"
            >
              <h1 className="text-white header-title">Active Tasks</h1>
              <h1 className="header-full-name">{user?.name}</h1>
            </div>
            <div className="task-options-wrapper">
              <div className="form-group has-search mb-5">
                <span className="fa fa-search form-control-feedback">
                  <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} />
                </span>
                <input
                  type="text"
                  className="form-control searchbar"
                  placeholder="Search for task"
                />
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className="page-item disabled border-0">
                    <a className="page-link-arrow" href="#" tabIndex={-1}>
                      <FontAwesomeIcon icon={faAngleLeft as IconProp} />
                    </a>
                  </li>
                  <li className="page-item px-1">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item px-1">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item arrows border-0">
                    <a className="page-link-arrow" href="#">
                      <FontAwesomeIcon icon={faAngleRight as IconProp} />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <table className="table table-stripe border">
              <tbody>
                <tr>
                  <td className="header-item">Status</td>
                  <td className="header-item">Task</td>
                  <td className="header-item">Replies</td>
                  <td className="header-item">Last Post</td>
                  <td className="header-item">Posted By</td>
                </tr>
                {tasks.map && tasks.map((task: any) => (
                  <tr key={task._id}>
                    <td>
                      <span className="badge active rounded-pill">Active</span>
                    </td>
                    <td>{task.name}</td>
                    <td>24</td>
                    <td>2:00pm 13-06-22</td>
                    {task.postedBy && (
                      <td className="assigned">
                        <div>      
                          <img
                            className="user_image"
                            src={task.postedBy.media[0].url}
                            alt=""
                          />
                          {task.postedBy.name}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
};

export default TasksPage;
