import "bootstrap/dist/css/bootstrap.min.css";
import "../Projects/Projects.css";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";
import ReactMarkdown from "react-markdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface Wiki {
  name: string;
  headerings: string[];
  content: string[];
}

const WikiDetails: React.FC = () => {
  const wikiId = useLocation().pathname;
  const paramWikiId = wikiId.split("/wiki-details/")[1];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const [selectedWiki, setSelectedWiki] = useState<Wiki>({
    name: "",
    headerings: [],
    content: [],
  });

  async function deleteWiki() {
    console.log("delete wiki");
    const response = await apiRequest({
      api: "DeleteWiki",
      params: `/${paramWikiId}`,
    });
    handleClose();
    handleNavigate("/overview");
  }

  async function getProjectWiki() {
    const wiki = await apiRequest({
      api: "GetWiki",
      params: `/${paramWikiId}`,
    });
    setSelectedWiki(wiki);
  }

  useEffect(() => {
    getProjectWiki();
  }, []);

  return (
    <div>
      <Navbar />
      <div
        className="d-flex"
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Sidebar />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="mt-5">
              <button
                type="button"
                className="btn btn-outline-info"
                onClick={() => {
                  handleNavigate(`/wiki-update/${paramWikiId}`);
                }}
              >
                Update Wiki
              </button>
              <button
                type="button"
                className="mx-3 btn btn-outline-danger"
                onClick={handleShow}
              >
                Delete Wiki
              </button>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete Wiki</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this Wiki?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="danger" onClick={deleteWiki}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="container-content bg-dark mt-5">
            <div className="overview-header-wrapper">
              <h1 className="projects-header-title text-light">
                {selectedWiki.name}
              </h1>
            </div>
          </div>

          {selectedWiki.content.map &&
            selectedWiki.content.map((item: string, index: any) => (
              <div className="mt-4 text-white" key={item}>
                <h2 className="wiki-header-title">
                  {selectedWiki.headerings[index]}
                </h2>
                <p className="wiki-header-details">
                  <ReactMarkdown>{item}</ReactMarkdown>
                </p>
                <hr className="wiki-divider" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default WikiDetails;
