import "bootstrap/dist/css/bootstrap.min.css";
import "../Threads/Threads.css"
import moment from 'moment';
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";
import { useLocation, useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faTrash } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ThreadsList: React.FC = () => {

    const taskId = useLocation().pathname
    const paramTaskId = taskId.split("/threads/")[1]

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const statusClass: { [key: string]: string } = {
        "not started": "rounded-pill badge not-started",
        "active": "rounded-pill badge active",
        "complete": "rounded-pill badge completed",
        "blocked": "rounded-pill badge blocked",
        "sticky": "rounded-pill badge sticky",
        "updates": "rounded-pill badge updates",
    };

    const [threadsArray, setThreadsArray] = useState([])
    const [taskMedia, setTaskMedia] = useState<string[]>([])
    const [task, setTask] = useState<any | null>(null)

    const navigate = useNavigate()

    const handleNavigate = (route: string) => {
        navigate(route)
    };

    async function getThreads() {
        const threads = await apiRequest({
            api: "GetTaskThread",
            params: `/${paramTaskId}`
        })
        setTask(threads.data[0].task)
        setThreadsArray(threads.data)
        fetchAllMedia(threads.data)
    }

    const dueDateFormatted = (date: Date) => {
        let formattedDate = (moment(date)).format('DD-MM-YY')
        return formattedDate
    }

    const projectCreated = (unixTime: number) => {
        const unixEpochTimeMS = unixTime * 1000;
        const date = new Date(unixEpochTimeMS);
        const timeAgo = moment(date).fromNow()
        return timeAgo
    };

    async function fetchAllMedia(threads: any) {
        const mediaArray: string[] = []
        threads.forEach((element: any) => {
            if (element.media.length > 0) {
                element.media.forEach((media: any) => {
                   mediaArray.push(media.url) 
                });
            }
        });

        setTaskMedia(mediaArray)
    }

    useEffect(() => {
        getThreads()
    }, []);

  return (
    <div>
        <Navbar />
            <div className="d-flex" style={{
                height: "100vh",
                overflow: "hidden"
            }}>
            <Sidebar />
            <div className="thread-content-wrapper" style={{
                "overflowY": "scroll",
                "paddingBottom": "50px",
            }}>
                <ul className="text-white thread-list">
                    {task  && (
                        <div style={{
                            "display": "flex",
                            "alignItems": "flex-start"
                        }}>
                            <h4 className="thread-title">{task.name}</h4>
                            <div className={statusClass[task.status]} style={{
                                    "top": "158%",
                                    "transform": "translateY(158%)",
                                    "marginLeft": "15px"
                            }}>
                                {task.status}
                            </div>
                        </div>
                        
                    )}
                    <button type="button" className="btn btn-outline-success mt-3" onClick={() => {
                        handleNavigate(`/threads/create/${paramTaskId}`)
                    }}>Create Post</button>
                    {threadsArray.map && threadsArray.map((item: any) => (
                        <div className={item.creator._id === task.assignee._id ? "thread-list-content-wrapper-active" : "thread-content-wrapper"} key={item._id}>
                            <div className="thread-img-header">
                                {item.creator._id === task.assignee._id ? (
                                    <div className="thread_img_owner"><img src={item.creator.media[0].url} alt="" /></div>
                                ) : (
                                    <div className="thread_img"><img src={item.creator.media[0].url} alt="" /></div>
                                )}
                                <h5 className="thread-header-name">{item.creator.name}</h5>
                                <div className="threads-bp" />
                                <h5 className="thread-header-position">{item.creator.position}</h5>
                                <div className="threads-bp" />
                                <h5 className="thread-header-posted">{projectCreated(item.inserted)}</h5>
                                {/* TODO: Check user is the user that create the post. */}
                                <FontAwesomeIcon className="delete-option" icon={faTrash as IconProp} onClick={handleShow} />
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                    <Modal.Title>Delete Post Confirmation</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure you want to delete this post?!</Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="danger" onClick={handleClose}>
                                        Delete Post
                                    </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div className="thread-content">
                                <p>{item.description}</p>
                                {item.media.length > 0 && (
                                    <img className="thread-image" src={item.media[0].url} alt="" />
                                )}
                            </div>
                        </div>
                    ))}
                </ul>
            </div>
            <div className="thread-table-wrapper">
            <table className="table thread-table table-dark table-borderless">
                <thead>
                    <tr>
                    <th scope="col">Brief</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    {task && (
                        <td>{task.description}</td>
                    )}
                    </tr>
                </tbody>
                <thead>
                    <tr>
                    <th scope="col">Assignee</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    {task && (
                        <td>{task.assignee.name}</td>
                    )}
                    </tr>
                </tbody>
                
                <thead>
                    <tr>
                    <th scope="col">Due Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    {task && (
                        <td>{dueDateFormatted(task.dueDate)}</td>
                    )}
                    </tr>
                </tbody>
                
                <thead>
                    <tr>
                    <th scope="col">Shared Media</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>
                        <div>
                            <div className="grid-container">
                            {taskMedia.map && taskMedia.map((item: any, index: any) => (
                                <div className="grid-item" key={index}>
                                    <img className="task-image-item" src={item} alt="" />
                                </div>
                            ))}
                            </div>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
    </div>
  );
};

export default ThreadsList;
