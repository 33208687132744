import "bootstrap/dist/css/bootstrap.min.css";
import "../Admin/Admin.css";
import { pagingApiRequest } from "../../Helper/ApiRequest";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFile } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faAngleRight,
  faCube,
  faMagnifyingGlass,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../Components/Navbar";
import { useNavigate } from "react-router";

const AdminOverview: React.FC = () => {
  const [usersArray, setUsersArray] = useState([]);
  const [searchText, setSearchText] = useState<string | null>(null);

  // Pagination
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState<number | null>(null);

  async function getUsers() {
    const users = await pagingApiRequest({
      api: "Users",
      params: searchText ? `?search=${searchText}` : undefined,
      page: currentPage,
    });
    setUsersArray(users.data);
    // Set pagination
    setCurrentPage(users.settings.page);
    setNextPage(users.settings.next);
    if (currentPage !== 1) {
      setPrevPage(currentPage - 1);
    } else {
      setPrevPage(null);
    }
  }

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPage]);

  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-center">
        <ul className="list-inline">
          <li
            className="admin-list-group-item"
            onClick={() => {
              handleNavigate("/create-project");
            }}
          >
            <FontAwesomeIcon
              className="admin-icon"
              icon={faFolder as IconProp}
            />
            New Project
          </li>
          <li className="admin-list-group-item" onClick={() => {
              handleNavigate("/create-category");
            }}>
            <FontAwesomeIcon className="admin-icon" icon={faCube as IconProp} />
            New Category
          </li>
          <li
            className="admin-list-group-item"
            onClick={() => {
              handleNavigate("/create-task");
            }}
          >
            <FontAwesomeIcon
              className="admin-icon"
              icon={faRocket as IconProp}
            />
            New Task
          </li>
          <li className="admin-list-group-item">
            <FontAwesomeIcon className="admin-icon" icon={faFile as IconProp} />
            New Wiki
          </li>
        </ul>
      </div>
      <hr />
      <div className="mx-5">
        <div className="admin-content-wrapper flex-fill">
          <div className="task-options-wrapper">
            <div className="form-group has-search mb-5">
              <span className="fa fa-search form-control-feedback">
                <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} />
              </span>
              <input
                type="text"
                className="form-control searchbar"
                placeholder="Search team members"
                onChange={(event) => {
                  const search = event.target.value.toString();
                  setSearchText(search);
                }}
              />
            </div>
            <nav aria-label="nav-page Page navigation example">
              <ul className="pagination justify-content-center">
                {prevPage && (
                  <li
                    className="page-item disabled border-0"
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                  >
                    <a className="page-link-arrow" href="#" tabIndex={-1}>
                      <FontAwesomeIcon icon={faAngleLeft as IconProp} />
                    </a>
                  </li>
                )}
                <li className="page-item px-1">
                  <div className="page-link">{currentPage}</div>
                </li>
                {nextPage && (
                  <li
                    className="page-item arrows border-0"
                    onClick={() => {
                      if (nextPage) {
                        setCurrentPage(nextPage);
                      }
                    }}
                  >
                    <a className="page-link-arrow" href="#">
                      <FontAwesomeIcon icon={faAngleRight as IconProp} />
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>

          <table className="table table-stripe border">
            <tbody>
              <tr>
                <td className="header-item"></td>
                <td className="header-item">Name</td>
                <td className="header-item">Role</td>
                <td className="header-item">Email Address</td>
                <td className="header-item">Restriction Groups</td>
                <td className="header-item"></td>
              </tr>
              {usersArray.map &&
                usersArray.map((item: any) => (
                  <tr key={item._id}>
                    <td></td>
                    <td>{item.name}</td>
                    <td>{item.roles[0].name}</td>
                    <td>{item.email}</td>
                    <td>2:00pm 13-06-22</td>
                    <td></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminOverview;
