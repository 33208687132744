import "bootstrap/dist/css/bootstrap.min.css";
import "../Tasks/Tasks.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";
import { useLocation, useNavigate } from "react-router";

interface Project {
  name: string;
}

const ProjectTasksPage: React.FC = () => {

  const projectId = useLocation().pathname
  const paramProjectId = projectId.split("/project-tasks/")[1]

  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const [project, setProject] = useState<Project | undefined>(undefined)
  const [tasks, setTasks] = useState([])

  async function getCurrentProject() {
    const response = await apiRequest({
        api: "Projects",
        params: `/${paramProjectId}`
    })
    setProject(response)
    return response
  }

  async function getProjectTasks() {
    const projectTaskResponse = await apiRequest({
      api: "GetProjectTasks",
      params: `/${paramProjectId}`
    })
    setTasks(projectTaskResponse)
  }

  useEffect(() => {
    getCurrentProject()
    getProjectTasks()
  }, [paramProjectId])

  return (
    <div>
      <Navbar />
        <div
          className="d-flex"
          style={{
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Sidebar />
          <div className="content-wrapper">
            <div
              className="d-flex p-4 pb-5
            pt-5 flex-row"
            >
              <h1 className="text-white header-title">Active Tasks</h1>
              {project && (
                <h1 className="header-full-name">{project.name}</h1>
              )}
            </div>
            <div className="task-options-wrapper">
              <div className="form-group has-search mb-5">
                <span className="fa fa-search form-control-feedback">
                  <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} />
                </span>
                <input
                  type="text"
                  className="form-control searchbar"
                  placeholder="Search for task"
                />
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className="page-item disabled border-0">
                    <a className="page-link-arrow" href="#" tabIndex={-1}>
                      <FontAwesomeIcon icon={faAngleLeft as IconProp} />
                    </a>
                  </li>
                  <li className="page-item px-1">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item px-1">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item arrows border-0">
                    <a className="page-link-arrow" href="#">
                      <FontAwesomeIcon icon={faAngleRight as IconProp} />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <table className="table table-stripe border">
              <tbody>
                <tr>
                  <td className="header-item">Status</td>
                  <td className="header-item">Task</td>
                  <td className="header-item">Assigned to</td>
                  <td className="header-item">Replies</td>
                  <td className="header-item">Last Post</td>
                </tr>
                {tasks.map && tasks.map((task: any) => (
                  <tr className="project-table-row" key={task._id} onClick={() => {
                    handleNavigate(`/threads/${task._id}`)
                  }}>
                    <td>
                      <span className="badge active rounded-pill">Active</span>
                    </td>
                    <td>{task.name}</td>
                    {task.assignee && (
                      <td className="assigned">
                        <div>      
                          <img
                            className="user_image"
                            src={task.assignee.media[0].url}
                            alt=""
                          />
                          {task.postedBy.name}
                        </div>
                      </td>
                    )}
                    <td>24</td>
                    <td>2:00pm 13-06-22</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
};

export default ProjectTasksPage;
