import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";
import {
  accessToken,
  getCookie,
  refreshToken,
  setCookie,
  userId,
} from "../../Helper/TokenService";
import "../Auth/Auth.css";
import { useNavigate } from "react-router-dom";
import playsideLogo from "../../Components/Images/PS_Logo_Wordmark_White.png";
import canvasLogo from "../../Components/Images/CANVAS_logo_teal.png";
import canvasBgLogo from "../../Components/Images/CANVAS_logo_background.png";
import { PublicClientApplication } from "@azure/msal-browser";
import { config } from "../../Config";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const [{ email, password }, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const handleLogin = async () => {
    const publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: config.authority,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      },
    });

    try {
      const response = await publicClientApplication.loginPopup({
        scopes: config.scopes,
        prompt: "select_account",
      });
      setErrorMessage(undefined);

      if (response.account) {
        const loginResponse = await apiRequest({
          api: "LoginSSO",
          body: {
            name: response.account.name,
            email: response.account.username,
          },
        });

        console.log(loginResponse);

        // Check for error response.
        if (loginResponse.error) {
          setErrorMessage(loginResponse.error);
          return;
        }
        // Handle successful login.
        // Set cookies & redirect
        setCookie(refreshToken, loginResponse.refreshToken);
        setCookie(accessToken, loginResponse.token);
        setCookie(userId, loginResponse.id);
        navigate("/overview");
      }
    } catch (error) {
      setErrorMessage(String(error) ?? "An error has occured.");
    }
  };

  useEffect(() => {
    const getAuthToken = async () => {
      if ((await getCookie(accessToken)) != null) {
        navigate("/overview");
      }
    };
    getAuthToken();
  }, []);

  return (
    <div className="container h-100 w-100 text-light">
      <div className="overall-container d-flex justify-content-md-center align-items-center">
        <img className="background-logo" src={canvasBgLogo} alt="" />
        <div className="login-form-wrapper">
          <div>
            <img className="login-ps-logo" src={playsideLogo} alt="" />
          </div>
          <img className="login-canvas-logo" src={canvasLogo} alt="" />

          <h1 className="welcome-label my-4">Welcome back</h1>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <div className="form-outline mb-3">
            <label className="login-label form-label">Email</label>
            <input
              type="email"
              className="email-field form-control"
              placeholder="Enter your email"
              value={email}
              onChange={(event) =>
                setCredentials({
                  email: event.target.value,
                  password,
                })
              }
            />
          </div>

          <div className="form-outline mb-4">
            <label className="login-label form-label">Password</label>
            <input
              type="password"
              className="password-field form-control"
              placeholder="•••••••••"
              value={password}
              onChange={(event) =>
                setCredentials({
                  email,
                  password: event.target.value,
                })
              }
            />
          </div>

          <div className="login-options-wrapper mb-4">
            <div className="col d-flex">
              <div className="form-check">
                <input
                  className="input-check form-check-input"
                  type="checkbox"
                  value=""
                />
                <label className="check-label form-check-label">
                  Remember me
                </label>
              </div>
            </div>

            <a className="login-forgot-password-label" href="#!">
              Forgot password
            </a>
          </div>
          <button
            className="w-100 btn login-btn"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
