import "bootstrap/dist/css/bootstrap.min.css";
import "../../Admin/Admin.css";
import { apiRequest } from "../../../Helper/ApiRequest";
import { useState } from "react";
import Navbar from "../../../Components/Navbar";

const CategoryCreate: React.FC = () => {
  const [categoryName, setCategoryName] = useState<string | null>(null);
  const [createdCategory, setCreatedCategory] = useState<string | null>(null);

  const handleCreateCategory = async () => {
    if (
      !categoryName
    ) {
      alert("Please fill out all the fields.");
    }

    const createdCategory = await apiRequest({
        api: "CreateCategories",
        body: {
          name: categoryName,
        },
      });
  
      if (createdCategory) {
        setCreatedCategory(createdCategory.name);
        // Reset values to default
        setCategoryName(null);
      }
  };
  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-center">
        <div className="form-container">
          <h2>Create New Category</h2>

          {createdCategory && (
            <div className="alert alert-success mt-4" role="alert">
              Category successfully created! {createdCategory}
            </div>
          )}

          <div className="form-wrapper">
            <div className="form-header" />
            <div className="form-content">
              {/* Category Forms */}

              <h5 className="form-title-name mt-4">Category Name</h5>
              <input
                type="text"
                value={categoryName || ""}
                className="form-control project-form w-100 mb-4"
                placeholder="Input Name"
                onChange={(event) => {
                  setCategoryName(event.target.value.toString());
                }}
              />
            </div>
          </div>
          <div className="project-button-wrapper">
            <button
              type="button"
              className="btn create-project-btn"
              onClick={() => {
                handleCreateCategory();
              }}
            >
              Create Category
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCreate;
