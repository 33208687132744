import "bootstrap/dist/css/bootstrap.min.css";
import "../../Admin/Admin.css";
import { apiRequest } from "../../../Helper/ApiRequest";
import { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";

const UserCreate: React.FC = () => {
  const [userName, setUserName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [position, setPosition] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [createdUser, setCreatedUser] = useState<string | null>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleCreateUser = async () => {
    // Check that passwords match
    if (password !== confirmPassword) {
      setErrorMessage("Error: Passwords do not match.");
      return
    }
    // Check that no field is empty
    if (
      !userName &&
      !email &&
      !position &&
      !role &&
      !password &&
      !confirmPassword
    ) {
      setErrorMessage("Error: One or more fields were left empty.");
      return
    }

    console.log(role)

    const createdUser = await apiRequest({
      api: "CreateUser",
      body: {
        "name": userName,
        "email": email,
        "position": position,
        "roles": [role],
        "password": password
      },
    });

    if (createdUser.error) {
      setErrorMessage(createdUser.error)
      return
    }

    setCreatedUser(createdUser.name)
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-center">
        <div className="form-container">
          <div className="form-content">
            <h2>Create User</h2>

            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}

            {createdUser && (
              <div className="alert alert-success" role="alert">
                Successfully created user: {createdUser}!
              </div>
            )}

            <h5 className="create-form-title mt-5 pb-2">User Name</h5>
            <input
              type="text"
              value={userName || ""}
              className="form-control project-form w-100 user-create-form"
              placeholder="Full Name"
              onChange={(event) => {
                setUserName(event.target.value.toString());
              }}
            />

            <h5 className="create-form-title mt-3 pb-2">Email Address</h5>
            <input
              type="text"
              value={email || ""}
              className="form-control project-form w-100 user-create-form"
              placeholder="Email Address"
              onChange={(event) => {
                setEmail(event.target.value.toString());
              }}
            />

            <h5 className="create-form-title mt-3 pb-2">Position</h5>
            <input
              type="text"
              value={position || ""}
              className="form-control project-form w-100 user-create-form"
              placeholder="Position"
              onChange={(event) => {
                setPosition(event.target.value.toString());
              }}
            />

            <h5 className="create-form-title mt-3 pb-2">Role</h5>
            <select
              value={role || ""}
              className="create-user-selector form-select user-create-form"
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="630c02e083375224b8dabaf0">Guest</option>
              <option value="62fd8bc35e631b63308cd9ab">User</option>
              <option value="5cd39a022428129d4c5c8ceb">Special</option>
              <option value="5cd399f92428129d4c5c8ce9">Moderator</option>
              <option value="5cd399fd2428129d4c5c8cea">Super Moderator</option>
            </select>

            <h5 className="create-form-title mt-3 pb-2">Password</h5>
            <input
              type="password"
              value={password || ""}
              className="form-control project-form w-100 user-create-form"
              placeholder="Password"
              onChange={(event) => {
                setPassword(event.target.value.toString());
              }}
            />
            <h5 className="create-form-title mt-3 pb-2">Confirm Password</h5>
            <input
              type="password"
              value={confirmPassword || ""}
              className="form-control project-form w-100 user-create-form"
              placeholder="Re-enter Password"
              onChange={(event) => {
                setConfirmPassword(event.target.value.toString());
              }}
            />

            <button
              type="button"
              className="btn create-project-btn"
              onClick={() => {
                handleCreateUser();
              }}
            >
              Create User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCreate;
