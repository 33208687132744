import "bootstrap/dist/css/bootstrap.min.css";
import "../Projects/Projects.css";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  accessToken,
  getCookie,
  refreshToken,
  removeCookie,
  userId,
} from "../../Helper/TokenService";

interface Project {
  name: string;
  description: string;
}

const ProjectOverview: React.FC = () => {
  const projectId = useLocation().pathname;
  const paramprojectId = projectId.split("/project-overview/")[1];

  const [selectedProject, setSelectedProject] = useState<Project>({
    name: "",
    description: "",
  });

  const [wikisArray, setWikis] = useState([]);

  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  async function getSelectedProject() {
    const project = await apiRequest({
      api: "Projects",
      params: `/${paramprojectId}`,
    });
    setSelectedProject(project);
  }

  async function getProjectWikis() {
    const wikis = await apiRequest({
      api: "Wikis",
      params: `?project=${paramprojectId}`,
    });
    setWikis(wikis);
  }

  async function checkAuthStatus() {
    // Check that access & refresh token exist
    if ((await getCookie(accessToken)) == undefined) {
      removeCookie(userId);
      removeCookie(accessToken);
      removeCookie(refreshToken);
      handleNavigate("/login");
    }
    if ((await getCookie(refreshToken)) == undefined) {
      removeCookie(userId);
      removeCookie(accessToken);
      removeCookie(refreshToken);
      handleNavigate("/login");
    }
  }

  useEffect(() => {
    checkAuthStatus();
    getSelectedProject();
    getProjectWikis();
  }, []);

  return (
    <div>
      <Navbar />
      <div
        className="d-flex"
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Sidebar />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="mt-3">
              <button
                type="button"
                className="btn btn-outline-info"
                onClick={() => {
                  handleNavigate(`/wiki-create/${paramprojectId}`);
                }}
              >
                Create Wiki
              </button>
            </div>
          </div>
          <div className="container-content bg-dark mt-5">
            <div className="overview-header-wrapper">
              <h1 className="projects-header-title text-light">
                {selectedProject.name}
              </h1>
              <h5 className="projects-header-title text-light">
                {selectedProject.description}
              </h5>
            </div>
          </div>

          <ul className="projects-list mt-5 text-light">
            {wikisArray.map &&
              wikisArray.map((item: any) => (
                <li
                  key={item._id}
                  className="project-list-item"
                  onClick={() => {
                    handleNavigate(`/wiki-details/${item._id}`);
                  }}
                >
                  <FontAwesomeIcon
                    className="project-list-icon"
                    icon={faCube as IconProp}
                    style={{
                      height: "25px",
                    }}
                  />
                  <h5>{item.name}</h5>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProjectOverview;
