import "bootstrap/dist/css/bootstrap.min.css";
import "../Components/Styling/Navbar.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell
} from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";
import { getCookie, userMedia } from "../Helper/TokenService";


const Navbar: React.FC = () => {

  const [userImage, setUserImage] = useState("https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png")

  useEffect(() => {
    async function getUserImage() {
      if ((await getCookie(userMedia)) !== undefined) {
        const userImageURL = await getCookie(userMedia)
        setUserImage(userImageURL!)
      }
    }
    getUserImage()
  }, [])

  return (
    <header className="header" id="header">
        <ul className="list-group list-group-horizontal border-0">
            <a><li className="list-group-item active border-0">Workspace</li></a>
            <a><li className="list-group-item border-0">Team</li></a>
            <a><li className="list-group-item border-0">Wiki</li></a>
        </ul>
        <div className="d-flex align-items-center justify-content-center flex-row">
            
        {/* Start Search bar */}
        <div className="form-group nav-searchbar ">
            <span className="fa fa-search form-control-feedback">
            <FontAwesomeIcon
                icon={faMagnifyingGlass as IconProp}
            /></span>
            <input type="text" className="form-control searchbar" placeholder="Search for task" />
        </div>
        {/* End Search bar */}
        
        <a href="#">
        <FontAwesomeIcon
            icon={faBell as IconProp}
            style={{
              color: "white",
              marginRight: "20px",
              height: "25px",
              width: "25px"
            }}
        />
        </a>
        <div className="header_img"> <img src={userImage} alt="" /> </div>
        </div>
    </header>
  );
};

export default Navbar;