import "bootstrap/dist/css/bootstrap.min.css";
import "../Components/Styling/Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFontAwesome,
  faAngleLeft,
  faAngleRight,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faStar,
  faSquareMinus,
  faSquareCheck,
  faRectangleList,
} from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { apiRequest } from "../Helper/ApiRequest";

const Sidebar: React.FC = () => {
  const [isShowingSidebar, setIsShowingSidebar] = useState(true);

  const [openProjects, setOpenProjects] = useState<string[]>([]);

  const navigate = useNavigate();

  const location = useLocation();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const handleRemoveProject = (id: string) => {
    setOpenProjects((prevId) => prevId.filter((value, i) => value !== id));
  };

  async function toggleProject(projectId: string) {
    if (openProjects.includes(projectId)) {
      handleRemoveProject(projectId);
    } else {
      setOpenProjects([...openProjects, projectId]);
    }
  }

  const [projectsArray, setProjectsArray] = useState([]);

  async function getProjects() {
    const projects = await apiRequest({
      api: "Projects",
    });
    setProjectsArray(projects.data);
  }

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <main className="sidebar d-flex flex-nowrap">
      <div
        className="flex-shrink-0"
        style={{
          width: isShowingSidebar ? "230px" : "70px",
          transition: "width 0.5s",
        }}
      >
        <button
          className="sidebar-toggle-btn"
          onClick={() => setIsShowingSidebar((state) => !state)}
        >
          {isShowingSidebar ? (
            <FontAwesomeIcon icon={faAngleLeft as IconProp} />
          ) : (
            <FontAwesomeIcon icon={faAngleRight as IconProp} />
          )}
        </button>
        <div className="sidebar-items">
          {!isShowingSidebar ? (
            <div>
              <li className="list-unstyled-short">
                <a
                  href="#"
                  className={
                    location.pathname == "/latest-posts"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFontAwesome as IconProp}
                    className="sidebar-icon"
                    style={{
                      height: "18px",
                    }}
                  />
                </a>
              </li>
              <li className="list-unstyled-short">
                <a
                  onClick={() => {
                    handleNavigate("/overview");
                  }}
                  className={
                    location.pathname == "/overview"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRectangleList as IconProp}
                    className="sidebar-icon"
                    style={{
                      height: "18px",
                    }}
                  />
                </a>
              </li>

              <li className="list-unstyled-short">
                <a
                  onClick={() => {
                    handleNavigate("/active-tasks");
                  }}
                  className={
                    location.pathname == "/active-tasks"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSquareMinus as IconProp}
                    className="sidebar-icon"
                    style={{
                      height: "18px",
                    }}
                  />
                </a>
              </li>
              <li className="list-unstyled-short">
                <a
                  href="#"
                  className={
                    location.pathname == "/completed-tasks"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSquareCheck as IconProp}
                    className="sidebar-icon"
                    style={{
                      height: "18px",
                    }}
                  />
                </a>
              </li>
              <li className="list-unstyled-short">
                <a
                  href="#"
                  className={
                    location.pathname == "/following"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                    marginLeft: "-2px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faStar as IconProp}
                    className="sidebar-icon"
                    style={{
                      height: "18px",
                    }}
                  />
                </a>
              </li>
            </div>
          ) : (
            <div>
              <li className="list-unstyled">
                <a
                  href="#"
                  className={
                    location.pathname == "/latest-posts"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFontAwesome as IconProp}
                    className="sidebar-icon"
                    style={{
                      marginRight: "10px",
                      height: "18px",
                    }}
                  />
                  Latest Posts
                </a>
              </li>
              <li className="list-unstyled">
                <a
                  onClick={() => {
                    handleNavigate("/overview");
                  }}
                  className={
                    location.pathname == "/overview"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRectangleList as IconProp}
                    className="sidebar-icon"
                    style={{
                      marginRight: "10px",
                      height: "18px",
                    }}
                  />
                  Overview
                </a>
              </li>

              <li className="list-unstyled sidebar-header mt-4 mb-2">
                MY TASKS
              </li>
              <li className="list-unstyled">
                <a
                  onClick={() => {
                    handleNavigate("/active-tasks");
                  }}
                  className={
                    location.pathname == "/active-tasks"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSquareMinus as IconProp}
                    className="sidebar-icon"
                    style={{
                      marginRight: "10px",
                      height: "18px",
                    }}
                  />
                  Active Tasks
                </a>
              </li>
              <li className="list-unstyled">
                <a
                  href="#"
                  className={
                    location.pathname == "/completed-tasks"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSquareCheck as IconProp}
                    className="sidebar-icon"
                    style={{
                      marginRight: "10px",
                      height: "18px",
                    }}
                  />
                  Completed Tasks
                </a>
              </li>
              <li className="list-unstyled">
                <a
                  href="#"
                  className={
                    location.pathname == "/following"
                      ? "sidebar-link-active link-light d-inline-flex text-decoration-none rounded"
                      : "sidebar-link link-light d-inline-flex text-decoration-none rounded"
                  }
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faStar as IconProp}
                    className="sidebar-icon"
                    style={{
                      marginRight: "8px",
                      height: "18px",
                    }}
                  />
                  Following
                </a>
              </li>
              <li className="list-unstyled sidebar-header mt-4">
                ALL PROJECTS
              </li>
              <ul className="list-unstyled ps-0">
                {projectsArray.map &&
                  projectsArray.map((item: any) => (
                    <li key={item.id} className="mt-2">
                      <div
                        className="sidebar-link link-light d-inline-flex text-decoration-none rounded"
                        onClick={() => toggleProject(item.id)}
                      >
                        <FontAwesomeIcon
                          icon={
                            openProjects.includes(item.id)
                              ? (faChevronDown as IconProp)
                              : (faChevronRight as IconProp)
                          }
                          className="project-sidebar-icon"
                          style={{}}
                        />
                        {item.name}
                      </div>
                      {openProjects.includes(item.id) && (
                        <ul
                          className="pb-1"
                          style={{
                            listStyle: "none",
                            marginLeft: "10px",
                          }}
                        >
                          <li>
                            <a
                              href="#"
                              className="sidebar-project-option link-light d-inline-flex text-decoration-none rounded"
                            >
                              Characters
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="sidebar-project-option link-light d-inline-flex text-decoration-none rounded"
                            >
                              Animation
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="sidebar-project-option link-light d-inline-flex text-decoration-none rounded"
                            >
                              Environments
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="sidebar-project-option link-light d-inline-flex text-decoration-none rounded"
                            >
                              User Interface
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="sidebar-project-option link-light d-inline-flex text-decoration-none rounded"
                            >
                              Outsource
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Sidebar;
