import "bootstrap/dist/css/bootstrap.min.css";
import "../Projects/Projects.css";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { apiRequest } from "../../Helper/ApiRequest";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router";

const ProjectsListPage: React.FC = () => {
  const [projectsArray, setProjectsArray] = useState([]);

  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  async function getProjects() {
    const projects = await apiRequest({
      api: "Projects",
    });
    setProjectsArray(projects.data);
  }

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div>
      <Navbar />
      <div
        className="d-flex"
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-content bg-dark mt-5">
            <h1 className="projects-header-title text-light">
              Projects Overview
            </h1>
          </div>
          <ul className="projects-list mt-5 text-light">
            {projectsArray.map &&
              projectsArray.map((item: any) => (
                <li
                  key={item.id}
                  className="project-list-item"
                  onClick={() => {
                    handleNavigate(`/project-overview/${item._id}`);
                  }}
                >
                  <FontAwesomeIcon
                    className="project-list-icon"
                    icon={faFolder as IconProp}
                    style={{
                      height: "25px",
                    }}
                  />
                  <h5>{item.name}</h5>
                  <p>{item.description}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProjectsListPage;
