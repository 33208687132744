export const accessToken = "access_token";
export const refreshToken = "refresh_token";
export const userMedia = "userMediaURL"
export const userId = "userId"

export async function getCookie(key: string) {
  return localStorage.getItem(key);
}

export async function setCookie(key: string, value: any) {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
  return localStorage.setItem(key, value);
}

export async function removeCookie(key: string) {
  return localStorage.removeItem(key);
}

export default { setCookie, getCookie, removeCookie };
