import "bootstrap/dist/css/bootstrap.min.css";
import "../Wiki/Wiki.css";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";
import Accordion from "react-bootstrap/Accordion";

interface Wiki {
  name: string;
  headerings: string[];
  content: string[];
}

const WikiUpdate: React.FC = () => {
  const wikiId = useLocation().pathname;
  const paramWikiId = wikiId.split("/wiki-update/")[1];

  const [selectedWiki, setSelectedWiki] = useState<Wiki>({
    name: "",
    headerings: [],
    content: [],
  });

  const [headerText, setHeaderText] = useState<string | null>(null);
  const [contentText, setContentText] = useState<string | null>(null);

  async function updateWiki() {
    const existingHeadering = selectedWiki.headerings;
    if (headerText) {
      existingHeadering.push(headerText);
    }

    const existingContent = selectedWiki.content;
    if (contentText) {
      existingContent.push(contentText);
    }

    console.log(existingContent, existingHeadering);

    const updateResponse = await apiRequest({
      api: "UpdateWiki",
      params: `/${paramWikiId}`,
      body: {
        headerings: existingHeadering,
        content: existingContent,
      },
    });

    console.log(updateResponse);
  }
  async function getProjectWiki() {
    const wiki = await apiRequest({
      api: "GetWiki",
      params: `/${paramWikiId}`,
    });
    setSelectedWiki(wiki);
  }

  useEffect(() => {
    getProjectWiki();
  }, []);

  return (
    <div>
      <Navbar />
      <div
        className="d-flex"
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-content bg-dark mt-5">
            <div className="overview-header-wrapper">
              <h1 className="projects-header-title text-light">
                Update - {selectedWiki.name}
              </h1>
            </div>
          </div>

          <Accordion className="mt-5" defaultActiveKey="0">
            {selectedWiki.headerings.map &&
              selectedWiki.headerings.map((item: any, index: number) => (
                <Accordion.Item eventKey={String(index)} key={index}>
                  <Accordion.Header>{item}</Accordion.Header>
                  <Accordion.Body>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        onChange={(event) => {
                          const newContent = event.target.value.toString();
                          // setSearchText(search);
                        }}
                        value={selectedWiki.content[index]}
                        rows={3}
                      ></textarea>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>

          <Accordion className="mt-3" defaultActiveKey="0">
            <Accordion.Item eventKey="5">
              <Accordion.Header>New Wiki Item</Accordion.Header>
              <Accordion.Body>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Wiki Header"
                    value={headerText ?? ""}
                    onChange={(event) => {
                      const headerText = event.target.value.toString();
                      setHeaderText(headerText);
                    }}
                  />
                  <textarea
                    className="form-control"
                    placeholder="Wiki Content"
                    value={contentText ?? ""}
                    onChange={(event) => {
                      const newContent = event.target.value.toString();
                      setContentText(newContent);
                    }}
                    rows={3}
                  ></textarea>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <button
            type="button"
            className="mt-5 btn btn-outline-info"
            onClick={(e) => {
              e.preventDefault();
              updateWiki();
            }}
          >
            Update Wiki
          </button>
        </div>
      </div>
    </div>
  );
};

export default WikiUpdate;
