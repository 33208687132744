/** Util type-checking for API error responses */
function isApiError(output: unknown): output is { error: string } {
  return (
    typeof output === "object" &&
    output !== null &&
    typeof (output as { error: string }).error === "string"
  );
}

type EmptyObject = {
  [K in any]: any;
};

const BASE_URL: string =
  process.env.NODE_ENV === "development"
    ? "http://localhost:7070/"
    : "http://localhost:7070/";

/** Defines the input parameters for API routes */
interface ApiRequestType {
  Login: {
    email: string;
    password: string;
  };
  LoginSSO: EmptyObject;
  RefreshToken: {
    token: string;
  };
  GetDepartment: EmptyObject;
  Teams: EmptyObject;
  Projects: EmptyObject;
  Tasks: EmptyObject;
  Categories: EmptyObject;
  Wikis: EmptyObject;
  GetWiki: EmptyObject;
  CreateWiki: EmptyObject;
  UpdateWiki: EmptyObject;
  DeleteWiki: EmptyObject;
  CreateProject: EmptyObject;
  CreateCategories: EmptyObject;
  CreateTask: EmptyObject;
  GetUserTasks: EmptyObject;
  GetProjectTasks: EmptyObject;
  CreateUser: EmptyObject;
  GetUser: EmptyObject;
  Users: EmptyObject;
  Threads: EmptyObject;
  CreateThread: EmptyObject;
  GetTaskThread: EmptyObject;
  UploadMedia: EmptyObject;
}

/** Defines the response type for API routes */
interface ApiResponseType extends Record<keyof ApiRequestType, any> {
  Login: {
    accessToken: string;
    refreshToken: string;
  };
  LoginSSO: EmptyObject;
  GetDepartment: EmptyObject;
  GetWiki: EmptyObject;
  CreateWiki: EmptyObject;
  UpdateWiki: EmptyObject;
  DeleteWiki: EmptyObject;
  RefreshToken: EmptyObject;
  Teams: EmptyObject;
  Projects: EmptyObject;
  Tasks: EmptyObject;
  Categories: EmptyObject;
  Wikis: EmptyObject;
  CreateProject: EmptyObject;
  CreateCategories: EmptyObject;
  CreateTask: EmptyObject;
  GetUserTasks: EmptyObject;
  GetProjectTasks: EmptyObject;
  CreateUser: EmptyObject;
  GetUser: EmptyObject;
  Users: EmptyObject;
  Threads: EmptyObject;
  CreateThread: EmptyObject;
  GetTaskThread: EmptyObject;
  UploadMedia: EmptyObject;
}

/** Util type for what needs to be included in API info below */
interface ApiInfo<Api extends keyof ApiRequestType> {
  method: "GET" | "POST" | "PUT" | "DELETE";
  route: string;
  authenticated: boolean;
  responseValidator(obj: unknown): obj is ApiResponseType[Api];
}

/** Defines the mapping from API friendly-names to the actual request details. */
const Info: { [Api in keyof ApiRequestType]: ApiInfo<Api> } = {
  RefreshToken: {
    method: "POST",
    route: "api/v1/users/token",
    authenticated: false,
    responseValidator(obj: unknown): obj is ApiResponseType["RefreshToken"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  Login: {
    method: "POST",
    route: "api/v1/users/login",
    authenticated: false,
    responseValidator(obj: unknown): obj is ApiResponseType["Login"] {
      return (
        typeof obj === "object" &&
        obj !== null &&
        typeof (obj as ApiResponseType["Login"]).accessToken === "string" &&
        typeof (obj as ApiResponseType["Login"]).refreshToken === "string"
      );
    },
  },
  LoginSSO: {
    method: "POST",
    route: "api/v1/users/login/sso/",
    authenticated: false,
    responseValidator(obj: unknown): obj is ApiResponseType["Login"] {
      return (
        typeof obj === "object" &&
        obj !== null &&
        typeof (obj as ApiResponseType["Login"]).accessToken === "string" &&
        typeof (obj as ApiResponseType["Login"]).refreshToken === "string"
      )
    },
  },
  UploadMedia: {
    method: "POST",
    route: "api/v1/media/upload",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["UploadMedia"] {
      return (
        typeof obj === "object" &&
        obj !== null
      )
    },
  },
  CreateUser: {
    method: "POST",
    route: "api/v1/users/create",
    authenticated: false,
    responseValidator(obj: unknown): obj is ApiResponseType["CreateUser"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetUser: {
    method: "GET",
    route: "api/v1/users",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["GetUser"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetDepartment: {
    method: "GET",
    route: "api/v1/department",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Teams"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  Teams: {
    method: "GET",
    route: "api/v1/team",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Teams"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  Categories: {
    method: "GET",
    route: "api/v1/categories",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Categories"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  Projects: {
    method: "GET",
    route: "api/v1/project",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Login"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  Wikis: {
    method: "GET",
    route: "api/v1/wikis/project/wikis",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Wikis"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetWiki: {
    method: "GET",
    route: "api/v1/wikis",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Wikis"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  Tasks: {
    method: "GET",
    route: "api/v1/task",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Tasks"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateProject: {
    method: "POST",
    route: "api/v1/project",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["CreateProject"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateCategories: {
    method: "POST",
    route: "api/v1/categories",
    authenticated: true,
    responseValidator(
      obj: unknown
    ): obj is ApiResponseType["CreateCategories"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateTask: {
    method: "POST",
    route: "api/v1/task",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["CreateTask"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetUserTasks: {
    method: "GET",
    route: "api/v1/task/user",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["GetUserTasks"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetProjectTasks: {
    method: "GET",
    route: "api/v1/task/project",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["GetProjectTasks"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateWiki: {
    method: "POST",
    route: "api/v1/wikis",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["CreateWiki"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateWiki: {
    method: "PUT",
    route: "api/v1/wikis",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["UpdateWiki"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteWiki: {
    method: "DELETE",
    route: "api/v1/wikis",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["DeleteWiki"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  Users: {
    method: "GET",
    route: "api/v1/users",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Users"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  Threads: {
    method: "GET",
    route: "api/v1/thread",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Categories"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateThread: {
    method: "POST",
    route: "api/v1/thread",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Categories"] {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetTaskThread: {
    method: "GET",
    route: "api/v1/thread/task",
    authenticated: true,
    responseValidator(obj: unknown): obj is ApiResponseType["Categories"] {
      return typeof obj === "object" && obj !== null;
    },
  }
};

/** Token refresh handling */
// export async function getToken(
//     isFirebaseRequest: boolean | undefined
// ): Promise<string | undefined> {
//     if (isFirebaseRequest) {
//         const accessToken = CookieService.get(firebaseAccessToken);
//         return accessToken;
//     } else {
//         const storedRefreshToken = CookieService.get(refreshToken);
//         const response = await apiRequest({
//             api: "RefreshToken",
//             body: {refreshToken: storedRefreshToken}
//         });

//         if (isApiError(response)) {
//             return undefined;
//         }

//         cycleTokens(response.accessToken, response.refreshToken);

//         return response.accessToken;
//     }
// }

interface UploadApiRequestParams<Api extends keyof ApiRequestType> {
  api: Api;
  params?: string;
  formData: FormData;
}

export async function uploadFormData<Api extends keyof ApiRequestType>({
  api,
  params,
  formData,
}: UploadApiRequestParams<Api>): Promise<any> {
  try {
    const { method, route, authenticated } = Info[api] as ApiInfo<Api>;

    const headers: HeadersInit = {};
    const bearer =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzE2YTAxZTYzODcyZTRjNWMxZWI1MWYiLCJpYXQiOjE2NjQ5MjM4MzksImV4cCI6MTc1MTIzNzQzOX0.bjEA3EwxC4rpQitims8sw_aS6Tk9T9wpST6MzGdmoqM";
    if (authenticated) {
      headers.Authorization = `Bearer ${await bearer}`;
    }
    // headers.Authorization = `Bearer ${await getToken(false)}`;

    let url = `${BASE_URL}${route}`;
    if (params) {
      url += `${params}`;
    }

    const res = await fetch(url, {
      method,
      headers,
      body: formData,
    });
    const output = await res.json();

    if (res.status >= 400) {
      throw new TypeError(`Unknown Error ${res.status}: ${res.statusText}`);
    }

    return output;
  } catch (e: any) {
    return { error: e ? e.message : "" };
  }
}

interface ApiRequestParams<Api extends keyof ApiRequestType> {
  api: Api;
  params?: string;
  body?: ApiRequestType[Api];
}

/** The actual generic request function */
export async function apiRequest<Api extends keyof ApiRequestType>({
  api,
  params,
  body,
}: ApiRequestParams<Api>): Promise<any> {
  try {
    const { method, route, authenticated } = Info[api] as ApiInfo<Api>;

    const headers: HeadersInit = { "Content-Type": "application/json" };
    const bearer =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzRlMmQ4NjZjNzg5MzYxNTk4ZjQyMDciLCJpYXQiOjE2NjYwNjc5NDcsImV4cCI6MTc1MjM4MTU0N30.hnD9XSlhnYnTmWhWMiPFemOuB8lfmMwYdPvIe-3pZS0";
    if (authenticated) {
      headers.Authorization = `Bearer ${await bearer}`;
    }

    let url = `${BASE_URL}${route}`;
    if (params) {
      url += `${params}`;
    }

    const res = await fetch(url, {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    });
    const output = await res.json();

    // if (res.status == 401 || res.status == 403) {
    //     CookieService.remove(refreshToken);
    //     CookieService.remove(accessToken);
    //     CookieService.remove(firebaseAccessToken);
    //     CookieService.remove(firebaseRefreshToken);
    // }

    if (isApiError(output)) {
      throw new TypeError(`Error: ${output.error}`);
    }

    if (res.status >= 400) {
      throw new TypeError(`Unknown Error ${res.status}: ${res.statusText}`);
    }

    return output;
  } catch (e: any) {
    return { error: e ? e.message : "" };
  }
}

interface PagingApiRequestParams<Api extends keyof ApiRequestType> {
  api: Api;
  params?: string;
  page?: number;
}

export async function pagingApiRequest<Api extends keyof ApiRequestType>({
  api,
  params,
  page,
}: PagingApiRequestParams<Api>): Promise<any> {
  try {
    const { method, route, authenticated } = Info[api] as ApiInfo<Api>;

    const headers: HeadersInit = { "Content-Type": "application/json" };
    const bearer =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzE2YTAxZTYzODcyZTRjNWMxZWI1MWYiLCJpYXQiOjE2NjI1MDg0MTIsImV4cCI6MTc0ODgyMjAxMn0._zpuQuYT7CIGm4ReG6IGg3WRgTz46mFZbw1Mwpgmevg";
    if (authenticated) {
      headers.Authorization = `Bearer ${await bearer}`;
    }

    let url = `${BASE_URL}${route}`;
    if (params) {
      url += `${params}&page=${page}`;
    } else {
      url += `?page=${page}`;
    }

    const res = await fetch(url, {
      method,
      headers,
    });
    const output = await res.json();

    if (isApiError(output)) {
      throw new TypeError(`Error: ${output.error}`);
    }

    if (res.status >= 400) {
      throw new TypeError(`Unknown Error ${res.status}: ${res.statusText}`);
    }

    return output;
  } catch (e: any) {
    return { error: e ? e.message : "" };
  }
}

export default { apiRequest, pagingApiRequest, uploadFormData };
