import "bootstrap/dist/css/bootstrap.min.css";
import "../Projects/Projects.css"
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { accessToken, getCookie, refreshToken, removeCookie, userId } from "../../Helper/TokenService";

interface Department {
    name: string;
    leads: any[];
    members: any[];
}

const DepartmentPage: React.FC = () => {

    const departmentId = useLocation().pathname
    const paramdepartmentId = departmentId.split("/department/")[1]

    const [department, setDepartment] = useState<Department | undefined>(undefined)

    const navigate = useNavigate();

    const handleNavigate = (route: string) => {
        navigate(route);
    };

    async function getDepartment() {
        const fetchedDepartment = await apiRequest({
            api: "GetDepartment",
            params: `/${paramdepartmentId}`
        })
        setDepartment(fetchedDepartment)
    }

    async function checkAuthStatus() {
        // Check that access & refresh token exist
        if ((await getCookie(accessToken)) == undefined) {
            removeCookie(userId)
            removeCookie(accessToken)
            removeCookie(refreshToken)
            handleNavigate("/login")
        }
        if ((await getCookie(refreshToken)) == undefined) {
            removeCookie(userId)
            removeCookie(accessToken)
            removeCookie(refreshToken)
            handleNavigate("/login")
        }
    }

    useEffect(() => {
        checkAuthStatus()
        getDepartment()
    }, []);

  return (
    <div>
        <Navbar />
            <div className="d-flex" style={{
                height: "100vh",
                overflow: "hidden"
            }}>
            <Sidebar />
            <div className="content-wrapper">
                <div className="container-content bg-dark mt-5">
                    <div className="overview-header-wrapper">
                        {department && (
                            <h1 className="projects-header-title text-light">{department.name}</h1>
                        )}
                    </div>
                </div>
                
                {/* Leads */}
                <div className="p-4">
                    <h2 className="department-list-title">Leads</h2>
                    <hr className="department-divider" />
                    <div className="users-wrapper">
                        {department?.leads.map && department.leads.map((item: any) => (
                            <div className="department-users-details-wrapper">
                                <img className="department-user-image" src={item.media[0].url} alt="" />
                                <h2 className="department-details-name">{item.name}</h2>
                                <h2 className="department-details-position">{item.position}</h2>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Leads */}
                <div className="p-4">
                    <h2 className="department-list-title">Team Members</h2>
                    <hr className="department-divider" />
                    <div className="users-wrapper">
                        {department?.members.map && department.members.map((item: any) => (
                            <div className="department-users-details-wrapper">
                                <img className="department-user-image" src={item.media[0].url} alt="" />
                                <h2 className="department-details-name">{item.name}</h2>
                                <h2 className="department-details-position">{item.position}</h2>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default DepartmentPage;