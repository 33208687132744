import "bootstrap/dist/css/bootstrap.min.css";
import "../../Admin/Admin.css";
import { apiRequest } from "../../../Helper/ApiRequest";
import { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";

const TaskCreate: React.FC = () => {
  const [taskName, setTaskName] = useState<string | null>(null);
  const [taskDesc, setTaskDesc] = useState<string | null>(null);

  const [selectedStatus, setSelectedStatus] = useState<string>("not started");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedAssignee, setSelectedAssignee] = useState<string>("");
  const [dueDate, setDueDate] = useState<string>("");

  const [selectedProject, setSelectedProject] = useState<string>("null");

  const [createdTask, setCreatedTask] = useState<string | null>(null);

  const handleCreateTask = async () => {
    if (!taskName || !taskDesc) {
      alert("Please fill out all the fields.");
    }

    const createdTask = await apiRequest({
      api: "CreateTask",
      body: {
        name: taskName,
        description: taskDesc,
        status: selectedStatus,
        hasLeadSignedOff: false,
        assignee: selectedAssignee,
        category: selectedCategory,
        dueDate: dueDate,
      },
    });

    if (createdTask) {
      setCreatedTask(createdTask.name);
      // Reset values to default
      setTaskName(null);
      setTaskDesc(null);
    }
  };

  const [usersArray, setUsersArray] = useState([]);
  const [projectsArray, setProjectsArray] = useState([]);
  const [categoriesArray, setCategoriesArray] = useState([]);

  async function getProjects() {
    const users = await apiRequest({
      api: "Projects",
    });
    setProjectsArray(users.data);
  }

  async function getCategories() {
    const categories = await apiRequest({
      api: "Categories",
    });
    setCategoriesArray(categories.data);
  }

  async function getUsers() {
    const users = await apiRequest({
      api: "Users",
    });
    setUsersArray(users.data);
  }

  useEffect(() => {
    getProjects();
    getCategories();
    getUsers();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-center">
        <div className="form-container">
          <h2>Create New Task</h2>

          {createdTask && (
            <div className="alert alert-success mt-4" role="alert">
              Task successfully created! {createdTask}
            </div>
          )}

          <div className="form-wrapper">
            <div className="form-header" />
            <div className="form-content">
              {/* Project Forms */}

              <div className="project-dropdown-wrapper mb-3">
                <div className="project-producer-wrapper">
                  <h6>Project</h6>
                  <select
                    className="form-control form-select selector"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedProject(e.target.value)}
                    defaultValue={selectedProject}
                  >
                    {projectsArray.map &&
                      projectsArray.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="project-team-wrapper">
                  <h6>Category</h6>
                  <select
                    className="form-control form-select selector"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    defaultValue={selectedCategory}
                  >
                    {categoriesArray.map &&
                      categoriesArray.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <h5 className="form-title-name">Task Name</h5>
              <input
                type="text"
                value={taskName || ""}
                className="form-control project-form w-100"
                placeholder="Input Name"
                onChange={(event) => {
                  setTaskName(event.target.value.toString());
                }}
              />

              <h5 className="form-title-desc">Task Brief</h5>
              <textarea
                className="form-control project-text-area w-100"
                placeholder="Input Description"
                value={taskDesc || ""}
                onChange={(event) => {
                  setTaskDesc(event.target.value.toString());
                }}
              />

              <div className="project-team-wrapper mt-4">
                <h6>Status</h6>
                <select
                  className="form-control form-select selector status-form"
                  aria-label="Default select example"
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  defaultValue={selectedStatus}
                >
                  <option value={"not started"}>Not Started</option>
                  <option value={"active"}>Active</option>
                  <option value={"complete"}>Complete</option>
                  <option value={"blocked"}>Blocked</option>
                  <option value={"sticky"}>Sticky</option>
                  <option value={"updates"}>Updates</option>
                </select>
              </div>

              <div className="project-dropdown-wrapper mb-3">
                <div className="project-producer-wrapper">
                  <h6>Assignee</h6>
                  <select
                    className="form-control form-select selector"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedAssignee(e.target.value)}
                    defaultValue={selectedAssignee}
                  >
                    {usersArray.map &&
                      usersArray.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="project-team-wrapper">
                  <h6>Due Date</h6>
                  <input
                    className="form-control selector"
                    type="date"
                    id="start"
                    name="trip-start"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="project-button-wrapper">
            <button
              type="button"
              className="btn create-project-btn"
              onClick={() => {
                handleCreateTask();
              }}
            >
              Create Task
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskCreate;
