import "bootstrap/dist/css/bootstrap.min.css";
import "../Wiki/Wiki.css";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { apiRequest } from "../../Helper/ApiRequest";
import Accordion from "react-bootstrap/Accordion";

interface Wiki {
  name: string;
  headerings: string[];
  content: string[];
}

const WikiCreate: React.FC = () => {
  const projectId = useLocation().pathname;
  const paramProjectId = projectId.split("/wiki-create/")[1];

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [nameText, setNameText] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  async function createWiki() {
    console.log("Create WIKI");

    if (!nameText) {
      setErrorMessage("Error: Please give the new wiki a name.");
      return;
    }

    const createdWikiResponse = await apiRequest({
      api: "CreateWiki",
      body: {
        name: nameText,
        project: paramProjectId,
      },
    });

    if (createdWikiResponse && createdWikiResponse.error) {
      setErrorMessage(createdWikiResponse.error);
      return;
    }

    handleNavigate(`/wiki-details/${createdWikiResponse._id}`);
  }

  return (
    <div>
      <Navbar />
      <div
        className="d-flex"
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-content bg-dark mt-5">
            <div className="overview-header-wrapper">
              <h1 className="projects-header-title text-light">
                Create New Wiki
              </h1>
            </div>
          </div>

          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          <Accordion className="mt-5" defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>New Wiki</Accordion.Header>
              <Accordion.Body>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Wiki Name"
                    value={nameText ?? ""}
                    onChange={(event) => {
                      const nameText = event.target.value.toString();
                      setNameText(nameText);
                    }}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <button
            type="button"
            className="mt-5 btn btn-outline-info"
            onClick={(e) => {
              e.preventDefault();
              createWiki();
            }}
          >
            Create Wiki
          </button>
        </div>
      </div>
    </div>
  );
};

export default WikiCreate;
