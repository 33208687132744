import "bootstrap/dist/css/bootstrap.min.css";
import "../../Admin/Admin.css";
import { apiRequest } from "../../../Helper/ApiRequest";
import { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";

const ProjectCreate: React.FC = () => {
  const [projectName, setProjectName] = useState<string | null>(null);
  const [projectDesc, setProjectDesc] = useState<string | null>(null);

  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const [selectedProducer, setSelectedProducer] = useState<string>("");

  const [createdProject, setCreatedProject] = useState<string | null>(null);

  const handleCreateProject = async () => {
    if (
      !projectName ||
      !projectDesc ||
      selectedTeam === "" ||
      selectedProducer === ""
    ) {
      alert("Please fill out all the fields.");
    }

    const createdProject = await apiRequest({
      api: "CreateProject",
      body: {
        name: projectName,
        description: projectDesc,
        producers: [selectedProducer],
        teams: [selectedTeam],
      },
    });

    if (createdProject) {
      setCreatedProject(createdProject.name);
      // Reset values to default
      setProjectName(null);
      setProjectDesc(null);
      setSelectedTeam("");
      setSelectedProducer("");
    }
  };

  const [teamsArray, setTeamsArray] = useState([]);
  const [usersArray, setUsersArray] = useState([]);

  async function getTeams() {
    const teams = await apiRequest({
      api: "Teams",
    });
    setTeamsArray(teams.data);
  }

  async function getUsers() {
    const users = await apiRequest({
      api: "Users",
    });
    setUsersArray(users.data);
  }

  useEffect(() => {
    getTeams();
    getUsers();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-center">
        <div className="form-container">
          <h2>Create New Project</h2>

          {createdProject && (
            <div className="alert alert-success mt-4" role="alert">
              Project successfully created! {createdProject}
            </div>
          )}

          <div className="form-wrapper">
            <div className="form-header" />
            <div className="form-content">
              {/* Project Forms */}

              <h5 className="form-title-name">Project Name</h5>
              <input
                type="text"
                value={projectName || ""}
                className="form-control project-form w-100"
                placeholder="Input Name"
                onChange={(event) => {
                  setProjectName(event.target.value.toString());
                }}
              />

              <h5 className="form-title-desc">Project Description</h5>
              <textarea
                className="form-control project-text-area w-100"
                placeholder="Input Description"
                value={projectDesc || ""}
                onChange={(event) => {
                  setProjectDesc(event.target.value.toString());
                }}
              />

              <div className="project-dropdown-wrapper">
                <div className="project-producer-wrapper">
                  <h6>Producer</h6>
                  <select
                    className="form-control form-select selector"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedProducer(e.target.value)}
                    defaultValue={selectedProducer}
                  >
                    {usersArray.map &&
                      usersArray.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="project-team-wrapper">
                  <h6>Team</h6>
                  <select
                    className="form-control form-select selector"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedTeam(e.target.value)}
                    defaultValue={selectedTeam}
                  >
                    {teamsArray.map &&
                      teamsArray.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="project-button-wrapper">
            <button
              type="button"
              className="btn create-project-btn"
              onClick={() => {
                handleCreateProject();
              }}
            >
              Create Project
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCreate;
